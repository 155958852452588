(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/local-storage/assets/javascripts/local-storage-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/local-storage/assets/javascripts/local-storage-actions.js');
"use strict";


const {
  Storage
} = svs.components;
const LOAD_FROM_LOCAL_STORAGE = 'LOAD_FROM_LOCAL_STORAGE';
const {
  LOCAL_STORAGE_NAME,
  LOCAL_STORAGE_DOMAIN
} = svs.components.marketplaceData.localStorage.constants;
const loadFromLocalStorage = () => dispatch => {
  const data = Storage.browser.get(LOCAL_STORAGE_NAME, LOCAL_STORAGE_DOMAIN);
  if (data) {
    dispatch({
      type: LOAD_FROM_LOCAL_STORAGE,
      payload: data
    });
  }
};
const clearFromLocalStorage = () => () => {
  Storage.browser.set(LOCAL_STORAGE_NAME, LOCAL_STORAGE_DOMAIN, undefined);
};
setGlobal('svs.components.marketplaceData.localStorage.actions', {
  loadFromLocalStorage,
  LOAD_FROM_LOCAL_STORAGE,
  clearFromLocalStorage
});

 })(window);