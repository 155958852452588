(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/local-storage/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/marketplace-data/local-storage/assets/javascripts/constants.js');
"use strict";


const constants = {
  LOCAL_STORAGE_NAME: 'marketplaceData',
  LOCAL_STORAGE_DOMAIN: 'svs.marketplace',
  DATA_EXPIRATION: 5 
};
setGlobal('svs.components.marketplaceData.localStorage.constants', constants);

 })(window);